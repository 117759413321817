import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

const IndexPage = () => (
  <Layout pageTitle="BLUME">
    <Seo title="BLUME Home"/>
    <StaticImage
      src="../images/BLUfinLogoClrNobg.svg"
      width={200}
      quality={95}
      formats={["auto", "svg", "webp", "avif"]}
      alt="BLUME finance -- AMM optimzed"
      style={{ marginBottom: `1.45rem` }}
    />
    <p/>
    <p>BLUME is a new type of AMM.</p>
    <p>Finally, there is an AMM that cares about <b>you</b>.</p>
    <p>We go <b>beyond constant product pricing</b>,
      bringing you fair compensation for providing liquidity.</p>
    <h2>Coming soon.</h2>
   </Layout>
)

export default IndexPage
